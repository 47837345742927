(function($){

	var initializeBlock = function( $block ) {
		var slider = $('.content-slider');

		slider.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			infinite: true,
			dots: false,
		});
	}

	// Initialize each block on page load (front end).
	$( document ).ready( function(){
		$( '.content-slider' ).each( function(){
			initializeBlock( $( this ) );
		});
	});

	// Initialize dynamic block preview (editor).
	if( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=content-slider', initializeBlock );
	}

})(jQuery);
